import { useCallback } from 'react';
import { useAuthContext } from '@indomita-react/auth-provider';
import { useRouter } from 'next/router';

import { useAtomicStateAtomValue } from 'src/atoms/atomic-state';
import { queryParamsStateAtom } from '../atoms/queryParamsStateAtom';

import { useAgencyQueryParams } from './useAgencyQueryParams';

import { useSendGA4Event } from 'src/tracking/ga/hooks/useSendGAEvent';
import { getGA4AgencyTrackingData } from 'src/tracking/ga/utils/store';
import { useIsMapCollapsed } from 'src/tracking/hooks/useIsMapCollapsed';
import { useTrack } from 'src/tracking/hooks/useTrack';
import { getSegmentAgencyTrackingData } from 'src/tracking/segment/utils';
import type { UserHashedEmailAndPhone } from 'src/tracking/types';
import {
  formatTransactionId,
  getContactType,
  getEntryPointFromReferrer,
  hashEmailAndPhone,
  TrackingDataAdvertiser,
} from 'src/tracking/utils';
import type { TrackHandleContactsClickType } from 'src/tracking/utils/enums';
import { EntryPoint, EventName } from 'src/tracking/utils/enums';
import {
  ContactCategory,
  ContactSubType,
  GATrackingEvent,
  PurchaseAndContactEventValue,
} from 'src/tracking/utils/enums';
import type {
  TrackAgencyContactSubmitArg,
  TrackingAgencyRealEstateListShownArgs,
} from '../tracking/types';
import {
  formatAgencySearchParams,
  getAgencyEventViewed,
  getAgencyViewedEntryPoint,
  getEntryPoint,
  getGA4AgencyInfo,
  getGA4AgencyItemId,
} from '../tracking/utils';

import { http } from 'src/utils/client/http';
import { getListMaxPages } from 'src/utils/real-estate';
import { capitalize } from 'src/utils/string';
import { formatRealEstateInfo } from 'src/utils/tracking/formatRealEstateInfo';

export const useAgencyTracking = () => {
  const { user } = useAuthContext();
  const maxPages = getListMaxPages(
    getGA4AgencyTrackingData()?.realEstateAds || 0
  );
  const sendGA4Event = useSendGA4Event();
  const isMapCollapsed = useIsMapCollapsed();
  const { pageType } = useAgencyQueryParams();

  const track = useTrack();

  const queryParams = useAtomicStateAtomValue(queryParamsStateAtom);
  const { sortBy, pag: currentPage, sortDir } = queryParams;

  const { query } = useRouter();

  const trackingListingsShown = useCallback(
    ({
      currentPage,
      totalPages,
      totalResults,
      realEstateListResult,
      queryParams,
      referrer,
    }: TrackingAgencyRealEstateListShownArgs & { referrer: string }) => {
      track(EventName.LISTINGS_SHOWN, {
        'Locality Country Selected': undefined,
        'Locality Region Selected': undefined,
        'Is User Logged': Boolean(user),
        'Total Pages': totalPages,
        ...formatAgencySearchParams(queryParams),
        ...formatRealEstateInfo(realEstateListResult),
        'Current Page': currentPage,
        'Number Of Results Returned': totalResults,
        'Entry Point':
          getEntryPointFromReferrer(referrer) === EntryPoint.ASSOCIATION_PAGE
            ? getEntryPointFromReferrer(referrer)
            : getEntryPoint(pageType),
      });
    },
    [track, user, pageType]
  );

  const trackHandleWebSiteClick = useCallback(() => {
    const agencyTrackingData = getSegmentAgencyTrackingData();

    if (!agencyTrackingData) {
      return;
    }

    track(EventName.WEB_SITE_CLICKED, agencyTrackingData);
  }, [track]);

  const trackHandleLocationClick = useCallback(() => {
    const agencyTrackingData = getSegmentAgencyTrackingData();

    if (!agencyTrackingData) {
      return;
    }

    track(EventName.AGENCY_LOCATION_BUTTON_PRESSED, agencyTrackingData);
  }, [track]);

  const trackHandleSwitchTab = useCallback(
    (selectedTab: string) => {
      const agencyTrackingData = getSegmentAgencyTrackingData();

      if (!agencyTrackingData) {
        return;
      }

      track(EventName.AGENCY_BOX_SECTION_SELECTED, {
        ...agencyTrackingData,
        'Tab Selected': capitalize(selectedTab),
      });
    },
    [track]
  );

  const trackHandleContactsClick = useCallback(
    //Todo: chiedere, visto che prima non c'era la CTA per i gruppi, se bisogna implementare qualcosa di diverso, tipo il source
    (type: TrackHandleContactsClickType, isGreenPhone = false) => {
      const agencyTrackingData = getSegmentAgencyTrackingData();

      if (type === 'telephone') {
        http.get(`/stats/statsAgenzia.php`, {
          searchParams: {
            idAgenzia: agencyTrackingData?.['ID Agency'],
            typeTrack: 'mostraTelefono',
            fieldTrack: type,
            source: 'D-AG-G',
            output: 'json',
            [Number(new Date()).toString()]: '',
          },
        });
      }

      if (!agencyTrackingData) {
        return;
      }

      track(EventName.AGENCY_CONTACT_REQUEST, {
        ...agencyTrackingData,
        'Type of contact': type === 'telephone' ? 'Telefono' : 'Messaggio',
        'Toll number': isGreenPhone,
      });
    },
    [track]
  );

  const trackPageViewed = useCallback(
    (referrer: string) => {
      const segmentAgencyTrackingData = getSegmentAgencyTrackingData();
      const ga4AgencytrackingData = getGA4AgencyTrackingData();
      const entryPoint = getEntryPoint(pageType);

      track(getAgencyEventViewed(pageType), {
        ...segmentAgencyTrackingData,
        ...formatAgencySearchParams(queryParams),
        'Listings IDs': ga4AgencytrackingData?.realEstateIdList,
        'Total Pages': maxPages,
        'Current Page': currentPage,
        'Sort Criteria': sortBy || 'relevance',
        'Sort Order': sortDir,
        'Number Of Results Returned': ga4AgencytrackingData?.realEstateAds,
        'Entry Point': getAgencyViewedEntryPoint(
          referrer,
          query.entryPoint as string | undefined,
          entryPoint
        ),
      });
    },
    [
      currentPage,
      maxPages,
      pageType,
      query.entryPoint,
      queryParams,
      sortBy,
      sortDir,
      track,
    ]
  );

  const trackAgencyContactSubmit = useCallback(
    ({
      purchaseAndContactEventValue,
      isVirtualPhone,
      contactData,
    }: TrackAgencyContactSubmitArg) => {
      const transaction_id = formatTransactionId(
        purchaseAndContactEventValue,
        contactData?.contactId
      );
      let contact_subtype: Optional<string> = undefined;
      let userTrackingData: UserHashedEmailAndPhone = {};

      if (purchaseAndContactEventValue !== PurchaseAndContactEventValue.EMAIL) {
        contact_subtype = isVirtualPhone
          ? ContactSubType.SMART
          : ContactSubType.NO_SMART;
      }

      if (
        (purchaseAndContactEventValue === PurchaseAndContactEventValue.EMAIL ||
          purchaseAndContactEventValue ===
            PurchaseAndContactEventValue.VISIT) &&
        !transaction_id
      ) {
        return;
      }

      if (
        (!user ||
          user.email !== contactData?.email ||
          user.phone !== contactData?.phone) &&
        purchaseAndContactEventValue === PurchaseAndContactEventValue.EMAIL
      ) {
        userTrackingData = hashEmailAndPhone({
          email: contactData?.email,
          phone: contactData?.phone,
        });
      }

      sendGA4Event(GATrackingEvent.PURCHASE, {
        ecommerce: {
          currency: 'EUR',
          transaction_id,
          value: purchaseAndContactEventValue,
          items: [],
          item_id: getGA4AgencyItemId(),
        },
      });

      sendGA4Event(GATrackingEvent.CONTACT, {
        ...getGA4AgencyInfo(),
        contact_id: transaction_id,
        advertiser: TrackingDataAdvertiser.AGENCY,
        value: purchaseAndContactEventValue,
        contact_category: ContactCategory.AGENCY,
        contact_subtype,
        contact_type: getContactType(purchaseAndContactEventValue),
        mode_selected: 'lista',
        is_map_collapsed: isMapCollapsed ? 'si' : 'no',
        ...userTrackingData,
      });
    },
    [user, isMapCollapsed, sendGA4Event]
  );

  return {
    trackHandleWebSiteClick,
    trackHandleLocationClick,
    trackHandleSwitchTab,
    trackHandleContactsClick,
    trackingListingsShown,
    trackPageViewed,
    trackAgencyContactSubmit,
  };
};
