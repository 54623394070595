import type { AgencyQueryParams } from 'src/repository/agency/types';

import { createAtomicStateStateAtom } from 'src/atoms/atomic-state';

import { updateGA4AgencyTrackingData } from 'src/tracking/ga/utils/store';

export const searchFormStateAtom =
  createAtomicStateStateAtom<AgencyQueryParams>({
    key: 'searchFormStateAtom',
    default: {},
    deepEqualityCheck: true,
    setup(self, { watch, get, set }) {
      watch(() => {
        set(self, get(queryParamsStateAtom));
      }, [queryParamsStateAtom]);
    },
  });

export const queryParamsStateAtom =
  createAtomicStateStateAtom<AgencyQueryParams>({
    key: 'queryParamsState',
    default: {},
    deepEqualityCheck: true,
    setup(self, { watch, get }) {
      watch(() => {
        const query = get(self);

        updateGA4AgencyTrackingData({
          realEstateAds: undefined,
          realEstateIdList: undefined,
          ...query,
        });
      }, [self]);
    },
  });
