import dynamic from 'next/dynamic';

import type { CoverPageMapProps } from './CoverPageMap';

const DynamicCoverPageMap = dynamic(
  () => import('./CoverPageMap').then((mod) => mod.CoverPageMap),
  { ssr: false }
);

// Refs doesn't work with dynamically imported components:
// https://github.com/vercel/next.js/issues/4957#issuecomment-783482041
export function CoverPageMap(props: CoverPageMapProps) {
  return <DynamicCoverPageMap {...props} />;
}

CoverPageMap.displayName = 'CoverPageMap';
