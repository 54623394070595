import { useTranslations } from '@pepita-react/i18n';
import clsx from 'clsx';

import { PepitaButton } from 'src/libs/ui/pepita-button';

import { useAgencyTracking } from 'src/views/Agency/hooks/useAgencyTracking';

import css from './styles.module.scss';

interface LocationLinkProps {
  address?: string;
  website?: string;
  latitude: Nullable<number>;
  longitude: Nullable<number>;
  onLocationClick: () => void;
}

export function LocationLink({
  address,
  website,
  latitude,
  longitude,
  onLocationClick,
}: LocationLinkProps) {
  const { trans } = useTranslations();
  const { trackHandleWebSiteClick, trackHandleLocationClick } =
    useAgencyTracking();

  if (!website && !(address && latitude && longitude)) {
    return null;
  }

  const handleClick = () => {
    onLocationClick();
    trackHandleLocationClick();
  };

  const handleWebsiteClick = () => {
    window.open(website, '_blank');
    trackHandleWebSiteClick();
  };

  return (
    <div className={css['in-locationLink']}>
      {website && (
        <PepitaButton
          icon={{ name: 'world' }}
          small
          variant="link"
          title={website}
          onClick={handleWebsiteClick}
          customClass={clsx(
            css['in-locationLink__buttons'],
            css['in-locationLink__website']
          )}
        >
          {trans('lbl_website', { capitalize: true })}
        </PepitaButton>
      )}

      {address && Boolean(latitude) && Boolean(longitude) && (
        <PepitaButton
          icon={{ name: 'marker' }}
          small
          variant="link"
          customClass={clsx(
            css['in-locationLink__buttons'],
            css['in-locationLink__address']
          )}
          onClick={handleClick}
        >
          <span className={css['in-locationLink__addressText']}>{address}</span>
        </PepitaButton>
      )}
    </div>
  );
}
