import { useRef } from 'react';
import { useSetAtom } from 'jotai';

import { showStickyHeaderAtom } from '../atoms/stickyHeader';

import { useIntersectionObserver } from 'src/hooks/useIntersectionObserver';

import css from './styles.module.scss';

export function CoverPageWrapper({ children, disabledStickyHeader = false }) {
  const ref = useRef<HTMLDivElement>(null);
  const setShowStickyHeader = useSetAtom(showStickyHeaderAtom);
  const handleChange = (isIntersecting: boolean) => {
    if (disabledStickyHeader) {
      return null;
    }

    return setShowStickyHeader(!isIntersecting); //we catch when the agency card is out of the viewport
  };

  useIntersectionObserver({
    ref,
    onChange: (isIntersecting) => handleChange(isIntersecting),
    root: 'document',
    enabled: true,
    margin: () => -100, //height at which header and formBar cover the cover
  });

  return (
    <div className={css['in-coverPageWrapper']} ref={ref}>
      {children}
    </div>
  );
}
