import { getListMaxPages } from './real-estate';

export const getSeoNextPage = (
  url: string,
  currentPage: number,
  totalAds: number
) => {
  // there is no pages or it is last page
  if (
    getListMaxPages(totalAds) === 0 ||
    currentPage === getListMaxPages(totalAds)
  ) {
    return;
  }

  return `${url}?pag=${currentPage + 1}`;
};
