import type { PropsWithChildren } from 'react';
import clsx from 'clsx';

import css from './styles.module.scss';

interface ContactsWrapperProps {
  isHorizontal?: boolean;
}

export function ContactsWrapper({
  isHorizontal,
  children,
}: PropsWithChildren<ContactsWrapperProps>) {
  return (
    <div
      className={clsx(
        css['in-contactsWrapper'],
        isHorizontal && css['in-contactsWrapper--horizontal']
      )}
    >
      {children}
    </div>
  );
}
