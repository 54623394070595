import type { PropsWithChildren, ReactNode } from 'react';
import clsx from 'clsx';

import { PepitaFigure } from 'src/libs/ui/pepita-figure';

import css from './styles.module.scss';

interface CoverPageHeaderProps {
  headerBackgroundColor?: string;
  image?: string;
  placeholderImage: ReactNode;
  figureCustomClass?: string;
  imageCustomClass?: string;
}

export function CoverPageHeader({
  image,
  headerBackgroundColor,
  placeholderImage,
  figureCustomClass,
  imageCustomClass,
  children,
}: PropsWithChildren<CoverPageHeaderProps>) {
  return (
    <div
      className={css['in-coverPageHeader']}
      style={{ backgroundColor: headerBackgroundColor }}
    >
      <PepitaFigure
        figureContent={image || placeholderImage}
        customClass={clsx(css['in-coverPageHeader__figure'], figureCustomClass)}
        imageCustomClass={clsx(
          css['in-coverPageHeader__image'],
          imageCustomClass
        )}
      />

      {children}
    </div>
  );
}
