import type { ReactNode } from 'react';
import { useEffect, useRef, useState } from 'react';
import { useTranslations } from '@pepita-react/i18n';
import clsx from 'clsx';

import { PepitaButton } from 'src/libs/ui/pepita-button';

import css from './styles.module.scss';

export type ReadAllProps = {
  enableToggle?: boolean;
  onReadAll?: () => void;
  children: ReactNode;
  customButtonClass?: string;
};

export function ReadAll({
  enableToggle = false,
  onReadAll,
  children,
  customButtonClass,
}: ReadAllProps) {
  const { trans } = useTranslations();
  const containerRef = useRef<HTMLDivElement>(null);
  const [maxHeight, setMaxHeight] = useState<Nullable<number>>(null);
  const [descriptionAll, setDescriptionAll] = useState<
    'showMore' | 'showLess' | 'hidden'
  >('showMore');
  //State to manage button visibility via CSS
  const [hasButton, setHasButton] = useState(false);

  useEffect(() => {
    if (!containerRef.current) return;

    // the max-height of the container is set in css to 3 row of text (72px) ,
    // to prevent the display of the button in the case of a single row of text
    // the comparison is calculated on 4 rows of text (96px)
    if (
      containerRef.current.scrollHeight - containerRef.current.clientHeight >
      96
    ) {
      setDescriptionAll('showMore');
      setHasButton(true);
    } else {
      setDescriptionAll('hidden');
    }
  }, []);

  const handleOnClick = () => {
    if (!containerRef.current) {
      return;
    }

    setMaxHeight(
      enableToggle && !maxHeight ? containerRef.current.scrollHeight : null
    );
    setDescriptionAll((prevState) => {
      if (!enableToggle) {
        return 'hidden';
      }

      return prevState === 'showMore' ? 'showLess' : 'showMore';
    });
    if (descriptionAll === 'showMore' && onReadAll) {
      onReadAll();
    }
  };

  return (
    <>
      <div
        ref={containerRef}
        className={clsx(
          css['in-readAll'],
          descriptionAll === 'showMore' && css['in-readAll--lessContent']
        )}
        style={maxHeight ? { maxHeight: `${maxHeight}px` } : undefined}
      >
        {children}
      </div>

      {descriptionAll !== 'hidden' && enableToggle && (
        <PepitaButton
          centered
          customClass={clsx(
            css['in-readAll__action'],
            !hasButton && 'is-invisible'
          )}
          onClick={handleOnClick}
          icon={{
            name: 'arrow-down',
            nameAlternate: 'arrow-top',
            isAlternate: descriptionAll === 'showLess',
          }}
        >
          {descriptionAll === 'showMore'
            ? trans('act_view_more')
            : trans('act_view_less')}
        </PepitaButton>
      )}

      {descriptionAll === 'showMore' && !enableToggle && (
        <PepitaButton
          centered
          customClass={clsx(
            css['in-readAll__action'],
            !hasButton && 'is-invisible',
            customButtonClass
          )}
          icon={{ name: 'arrow-down' }}
          onClick={handleOnClick}
        >
          {trans('act_read_more')}
        </PepitaButton>
      )}
    </>
  );
}
