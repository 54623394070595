import { useTranslations } from '@pepita-react/i18n';
import clsx from 'clsx';

import { PepitaButton } from 'src/libs/ui/pepita-button';

import { TrackHandleContactsClickType } from 'src/tracking/utils/enums';

import { useAgencyTracking } from 'src/views/Agency/hooks/useAgencyTracking';

import css from './styles.module.scss';

enum Styles {
  block = 'ag-greenPhone--block',
  button = 'ag-greenPhone__button',
  title = 'ag-greenPhone__title',
}
interface GreenPhoneProps {
  number: string;
  block?: boolean;
}

export function GreenPhone({ number, block = false }: GreenPhoneProps) {
  const { trans } = useTranslations();
  const { trackHandleContactsClick } = useAgencyTracking();

  return (
    <PepitaButton
      as="a"
      href={`tel:${number}`}
      block={block}
      customClass={clsx(block && css[Styles.block], css[Styles.button])}
      icon={{ name: 'phone' }}
      onClick={() =>
        trackHandleContactsClick(TrackHandleContactsClickType.TELEPHONE)
      }
    >
      <span>
        <span className={css[Styles.title]}>{trans('green_number')}</span>
        <br />
        {number}
      </span>
    </PepitaButton>
  );
}
