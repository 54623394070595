import { useCallback, useState } from 'react';
import { useTranslations } from '@pepita-react/i18n';
import type { AgencyPhoneType } from 'src/repository/agency/types';

import { PepitaButton } from 'src/libs/ui/pepita-button';
import { PepitaDialog, PepitaDialogFrameType } from 'src/libs/ui/pepita-dialog';

import { GreenPhone } from '../GreenPhone';

interface PhoneProps {
  number: string;
  type: AgencyPhoneType;
  block?: boolean;
  customClass?: string;
  onShowPhoneClick?: () => void;
  viewInModal?: boolean;
}

export function Phone({
  number,
  type,
  block = false,
  customClass,
  onShowPhoneClick,
  viewInModal = false,
}: PhoneProps) {
  const { trans } = useTranslations();
  const [showNumber, setShowNumber] = useState(false);
  const [openCallModal, setOpenCallModal] = useState(false);

  const onContactClick = useCallback(() => {
    if (viewInModal) {
      setOpenCallModal(true);
    } else {
      setShowNumber(true);
    }

    onShowPhoneClick && onShowPhoneClick();
  }, [viewInModal, onShowPhoneClick]);

  if (type === 'green-phone') {
    return <GreenPhone number={number} block={block} />;
  }

  if (showNumber && !viewInModal) {
    return (
      <PepitaButton
        as="a"
        href={`tel:${number}`}
        block={block}
        customClass={customClass}
      >
        {number}
      </PepitaButton>
    );
  }

  return (
    <>
      <PepitaButton
        block={block}
        onClick={onContactClick}
        customClass={customClass}
      >
        {type === 'cell' ? trans('lbl_mobile_phone') : trans('lbl_phone')}
      </PepitaButton>
      {openCallModal ? (
        <PepitaDialog
          title={trans('act_call_private', {
            capitalize: true,
          })}
          onClose={() => setOpenCallModal(false)}
          size={PepitaDialogFrameType.DIALOG}
          content={
            <PepitaButton
              as="a"
              variant="link"
              centered
              href={`tel:${number}`}
              icon={{ name: 'phone' }}
            >
              {number}
            </PepitaButton>
          }
        />
      ) : null}
    </>
  );
}
